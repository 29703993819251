<template>
  <div
    id="spellcaster-node"
    class="w-[500px] cursor-default p-[2px] rounded-lg shadow-md flex flex-col animate-gradient"
    :class="[
      'bg-gradient-to-r',
      'from-[#CC80FF]',
      'via-[#656BFF]',
      'to-[#CC80FF]',
      'bg-[length:200%_auto]',
    ]"
  >
    <div
      class="bg-white p-4 rounded-md shadow-md flex flex-col w-full"
      :class="{ 'min-h-[246px] items-center justify-center': isGenerating }"
    >
      <template v-if="!isGenerating">
        <div class="flex items-center justify-between mb-2 w-full">
          <div class="flex items-center">
            <UIcon
              name="i-respell-spellcaster"
              class="text-primary-500 w-6 h-6 mr-2"
            />
            <h3 class="text-lg font-semibold text-gray-800">Spellcaster</h3>
            <UBadge
              label="Beta"
              color="primary"
              variant="subtle"
              class="ml-2"
            />
          </div>
        </div>
        <form class="w-full" @submit.prevent="handleSubmit">
          <label
            for="spellcaster-prompt"
            class="text-sm font-semibold text-gray-700"
          >
            What would you like to automate?
          </label>
          <div
            class="flex mt-2 flex-col border rounded-md p-2 focus-within:border-gray-300"
            :class="{ 'nodrag nopan': props.selected }"
          >
            <UTextarea
              id="spellcaster-prompt"
              v-model="prompt"
              :placeholder="currentPlaceholder"
              class="w-full disabled:opacity-50 ring-0 rounded-none resize-none mb-2 nowheel"
              variant="none"
              textarea-class="nodrag nopan rounded-none resize-none mb-2 nowheel"
              :padded="false"
              :disabled="isGenerating"
              :rows="4"
              autofocus
            />

            <div class="flex items-center justify-between w-full">
              <UTooltip
                class="flex-1"
                :text="getTooltipText"
                :popper="{
                  placement: 'bottom-start',
                  offsetDistance: -4,
                  resize: true,
                  adaptive: true,
                }"
              >
                <UProgress
                  :value="prompt.length"
                  :max="PROMPT_MIN_LENGTH"
                  size="sm"
                  :color="prompt.length > PROMPT_MAX_LENGTH ? 'red' : 'primary'"
                  class="py-3"
                />
              </UTooltip>

              <UTooltip
                :text="!isPromptValid ? getTooltipText : ''"
                :prevent="isPromptValid"
              >
                <UButton
                  icon="i-ph-sparkle"
                  color="primary"
                  variant="solid"
                  :ui="{ rounded: 'rounded-full' }"
                  class="ml-2 nodrag nopan self-end"
                  type="submit"
                  :loading="isGenerating"
                  :disabled="isGenerating || !isPromptValid"
                  :class="{
                    'opacity-50 cursor-not-allowed': !isPromptValid,
                  }"
                >
                  Generate
                </UButton>
              </UTooltip>
            </div>
          </div>
          <p v-if="errorMessage" class="mt-2 text-red-500 text-sm">
            {{ errorMessage }}
          </p>
        </form>
      </template>
      <template v-else>
        <div class="flex items-center justify-center mb-4">
          <client-only>
            <Vue3Lottie
              :animation-data="loadingAnimation"
              :height="50"
              :width="50"
            />
          </client-only>
        </div>
        <p
          class="text-lg font-semibold bg-clip-text text-transparent animate-gradient"
          :class="[
            'bg-gradient-to-r',
            'from-[#CC80FF]',
            'via-[#656BFF]',
            'to-[#CC80FF]',
            'bg-[length:200%_auto]',
          ]"
        >
          {{ generatingMessages[generatingMessageIndex] }}
        </p>
        <p class="mt-4 text-sm text-gray-500 text-center">
          We're working our magic. This should only take a moment.
        </p>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { NodeProps } from '@vue-flow/core';
import { computed, onMounted, ref } from 'vue';
import { Vue3Lottie } from 'vue3-lottie';
import loadingAnimation from '~/assets/lotties/sparkle.json';
import { useSpellcasterStore } from '~/stores/spellcaster';
import { useSpellsStore } from '~/stores/spells';

const PROMPT_MIN_LENGTH = 30;
const PROMPT_MAX_LENGTH = 4000;
const TYPING_SPEED = 30;
const DELETING_SPEED = 5;
const PAUSE_BETWEEN = 4000;

const props = defineProps<NodeProps>();
const prompt = ref('');
const isGenerating = ref(false);
const errorMessage = ref('');
const currentPlaceholder = ref('');

const spellcasterStore = useSpellcasterStore();
const spellStore = useSpellsStore();

const { spell } = storeToRefs(spellStore);

const placeholderExamples = [
  // "When a new candidate is created in Ashby, search LinkedIn for related posts and add findings to the candidate's profile.", // can't do this yet
  'When I get a new Typeform submission, create a Google Doc and notify the team in Slack.',
  'Given a blog topic, generate an outline and save it as a Google Doc.',
  'When a conversation ends in Intercom, create a summary and draft a reply.',
  // 'When given a query, search LinkedIn posts and send a Slack message with the findings.', // can't do this yet
  'When a new Zendesk ticket is created, add it to Airtable and notify the support team in Slack.',
  'When I get an email in Gmail, generate a reply and create a draft in Gmail.',
  'When a new Ashby candidate is created, make a Linear issue with the candidate’s details.',
  'When a Google Calendar event is created, send a Slack message with the event details.',
  'When a new Gmail email arrives, summarize it and send it to me via Slack.',
  'When a Notion page is created, summarize it and save it as a Google Doc.',
  'When a Google Sheets row is created, send a follow-up text message to the listed contact.',
  'When a new Slack message matches a keyword, save it to a Google Sheet.',
  'When a new email arrives in Gmail, create a task in Linear and notify me in Slack.',
  'When a new Zendesk ticket is created, notify a Slack channel with ticket details.',
  'Given a topic, create a meeting agenda in a Google Doc.',
  'Given a research topic, create a summary and email it to a client using Gmail.',
  'Given an event title, time, and invitee, create a Google Calendar event and invite the person.',
  'Given a keyword, search Slack and list recent messages with it.',
];
// Messages shown while workflow is being generated
const generatingMessages = [
  'Generating your workflow...',
  'Creating steps...',
  'Finalizing workflow...',
];
// Tracks which generating message is currently displayed
const generatingMessageIndex = ref(0);

// Computed property that returns appropriate tooltip text based on prompt length
const getTooltipText = computed(() => {
  if (prompt.value.length > PROMPT_MAX_LENGTH) {
    return `Maximum ${PROMPT_MAX_LENGTH} characters allowed. Please shorten your prompt.`;
  }
  return prompt.value.length >= PROMPT_MIN_LENGTH
    ? 'Looking good! \nMore detail helps craft a better workflow'
    : `Enter at least ${PROMPT_MIN_LENGTH - prompt.value.length} more characters`;
});

// Computed property that validates if prompt length is within allowed range
const isPromptValid = computed(() => {
  return (
    prompt.value.length >= PROMPT_MIN_LENGTH &&
    prompt.value.length <= PROMPT_MAX_LENGTH
  );
});

// Animates typing out a single example text character by character
const typeText = async (text: string) => {
  // Type characters one by one
  for (let i = 0; i <= text.length; i++) {
    currentPlaceholder.value = text.substring(0, i);
    await new Promise((resolve) => setTimeout(resolve, TYPING_SPEED));
  }
  // Pause when fully typed
  await new Promise((resolve) => setTimeout(resolve, PAUSE_BETWEEN));

  // Delete characters one by one
  for (let i = text.length; i >= 0; i--) {
    currentPlaceholder.value = text.substring(0, i);
    await new Promise((resolve) => setTimeout(resolve, DELETING_SPEED));
  }
};

// Continuously animates placeholder text with random examples
const animatePlaceholder = async () => {
  while (true) {
    const randomIndex = Math.floor(Math.random() * placeholderExamples.length);
    await typeText(placeholderExamples[randomIndex]);
  }
};

// Start placeholder animation when component mounts
onMounted(() => {
  animatePlaceholder();
});

// Handles form submission to generate a new spell/workflow
const handleSubmit = async () => {
  errorMessage.value = '';
  // Validate minimum length
  if (prompt.value.length < PROMPT_MIN_LENGTH) {
    errorMessage.value = `Please enter at least ${PROMPT_MIN_LENGTH} characters`;
    return;
  }

  isGenerating.value = true;

  // Rotate through generating messages every 3.5 seconds
  const messageRotation = setInterval(() => {
    if (generatingMessageIndex.value < generatingMessages.length - 1) {
      generatingMessageIndex.value++;
    }
  }, 3500);

  try {
    // Call store action to generate the spell
    await spellcasterStore.generateSpell(prompt.value);
  } catch (error) {
    console.error('Error generating spell:', error);
    errorMessage.value =
      'There was an issue generating the workflow. Please try again.';
  } finally {
    // Clean up message rotation interval
    clearInterval(messageRotation);
    isGenerating.value = false;
  }
};
</script>

<style>
@keyframes gradient {
  0% {
    background-position: 200% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient {
  animation: gradient 6s linear infinite;
  background-size: 200% 100%;
}
</style>
