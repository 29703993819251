<script setup lang="ts">
import definitions from '@respell/steps';
import type { NodeProps } from '@vue-flow/core';
import { Handle, Position, useHandleConnections } from '@vue-flow/core';
import { triggerType } from '~/stores/canvas';
import AddStepButton from './AddStepButton.vue';
import NodeCard from './NodeCard.vue';

const props = defineProps<NodeProps>();
const canvasStore = useCanvasStore();

const stepType = computed(() =>
  props.type === 'start' ? definitions.start : triggerType,
);

const activeSources = useHandleConnections({
  nodeId: props.id,
  type: 'source',
});

watch(activeSources, () => {
  canvasStore.validateStep(props.data.slug, props.data.key, props.data.options);
});
</script>

<template>
  <Handle
    type="source"
    :position="Position.Right"
    class="handle"
    :class="{ 'is-connected': activeSources.length }"
  />

  <div
    v-if="!activeSources.length"
    class="absolute -top-[20px] -right-1/4 h-24 mr-s flex flex-row max-w-16 items-center"
    @click.stop
  >
    <div class="w-12 h-[1px] shrink bg-gray-300 rounded-full" />
    <AddStepButton :source="props.id" type="start" />
  </div>

  <NodeCard v-bind="props" :node-type="type" :step-type="stepType" />
</template>
<style lang="scss" scoped>
.handle {
  width: 16px;
  height: 16px;
}
</style>
