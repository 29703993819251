<script setup lang="ts">
import ConfirmModal from '~/components/modals/ConfirmModal.vue';

const modal = useModal();
const canvasStore = useCanvasStore();
const { selectedStep } = useSelectedStep();

const startOptions = computed(() => [
  {
    type: 'start',
    label: 'Manual',
    icon: 'i-ph-play-circle',
    description: 'A step that requires manual input',
    isActive: selectedStep.value?.type === 'start',
  },
  {
    type: 'trigger',
    label: 'Trigger',
    icon: 'i-ph-lightning',
    description: 'A step that starts automatically',
    isActive: selectedStep.value?.type === 'trigger',
  },
]);

const toggleStartType = (type: string) => {
  if (selectedStep.value?.type === 'add-start') {
    canvasStore.updateStartType(type);
  } else {
    modal.open(ConfirmModal, {
      type: 'start type',
      action: 'change',
      message: "Doing so may affect any steps that depend on this step's data",
      onConfirm() {
        canvasStore.updateStartType(type);
        modal.close();
      },
    });
  }
};
</script>
<template>
  <UAccordion
    variant="ghost"
    :ui="{ item: { size: 'text-2xl' } }"
    class="border-b pb-s border-gray-200"
    :items="[
      {
        label: 'Select start type',
        defaultOpen: true,
        slot: 'start-type',
      },
    ]"
  >
    <template #default="{ item, index, open }">
      <UButton color="gray" variant="ghost" block>
        <p id="choose-start-label" class="subtitle">Select start type</p>
        <template #trailing>
          <UIcon
            name="i-ph-caret-right"
            class="text-xl ms-auto transform transition-transform duration-200"
            :class="[open && 'rotate-90']"
          />
        </template>
      </UButton>
    </template>
    <template #start-type>
      <div
        v-for="option in startOptions"
        :key="option.type"
        class="flex flex-col gap-1 w-full"
      >
        <div
          v-if="option.type"
          class="flex flex-row justify-between hover:bg-primary-50 cursor-pointer w-full transition-colors p-m border border-gray-200 mt-s rounded-lg"
          :class="{
            'bg-primary-100': option.isActive,
          }"
          @click="() => toggleStartType(option.type as string)"
        >
          <div class="flex flex-col items-start space-y-2">
            <div class="flex flex-row items-center space-x-2">
              <UIcon
                :name="option.icon"
                class="text-2xl"
                :class="option.isActive ? 'text-primary-500' : 'text-black'"
              />
              <p
                class="title"
                :class="option.isActive ? 'text-primary-500' : 'text-black'"
              >
                {{ option.label }}
              </p>
            </div>
            <p
              class="body"
              :class="option.isActive ? 'text-primary-500' : 'dimmed'"
            >
              {{ option.description }}
            </p>
          </div>
          <input
            type="radio"
            :checked="option.isActive"
            class="h-5 w-5 accent-primary-500"
          />
        </div>
      </div>
    </template>
  </UAccordion>
</template>
