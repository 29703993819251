<script setup lang="ts">
import type { NodeProps } from '@vue-flow/core';
import {
  Handle,
  Position,
  useHandleConnections,
  useVueFlow,
} from '@vue-flow/core';
const props = defineProps<NodeProps>();

const emit = defineEmits(['remove']);

const canvasStore = useCanvasStore();
const { getConnectedEdges, removeEdges, addSelectedNodes, removeNodes } =
  useVueFlow({
    id: 'editor',
  });

onMounted(() => {
  addSelectedNodes([props]);
});

const activeSources = useHandleConnections({
  nodeId: props.id,
  type: 'source',
});

const activeTargets = useHandleConnections({
  nodeId: props.id,
  type: 'target',
});

//Destroy self and connected edges when unselected
watch(props, () => {
  if (!props.selected) {
    const connectedEdges = getConnectedEdges(props.id);
    // If adding between two nodes, recreate former edge
    if (connectedEdges.length === 2) {
      canvasStore.addEdge({
        source: connectedEdges[0].source,
        target: connectedEdges[1].target,
      });
    }
    removeEdges(connectedEdges);
    removeNodes([props.id]);
    emit('remove');
  }
});

defineShortcuts({
  backspace: {
    handler: () => {
      removeNodes([props.id]);
    },
  },
});
</script>

<template>
  <Handle
    type="target"
    :position="Position.Left"
    class="handle"
    :class="{ 'is-connected': activeTargets.length }"
  />
  <Handle
    type="source"
    :position="Position.Right"
    class="handle"
    :class="{ 'is-connected': activeSources.length }"
  />

  <div
    class="rounded-lg transition-all bg-container overflow-hidden border-[1.5px] flex flex-row justify-start w-80 h-16 px-m"
    :class="
      props.selected
        ? 'border-container-active shadow-node-active'
        : 'border-container hover:shadow-select'
    "
  >
    <div class="flex p-xs bg-primary-200 radius-s mr-s">
      <UIcon name="i-ph-plus-circle-bold" class="text-2xl text-primary-500" />
    </div>

    <p class="body is-bold">Add a step</p>
  </div>
</template>
<style lang="scss">
.handle {
  width: 1rem !important;
  height: 1rem !important;
  background-color: #e7e7ef;
  outline: 1.5px solid #e7e7ef;
  border: 2px solid #fcfdff;
  transition: 0.3s;

  &.is-connected,
  :hover {
    background-color: color('icon', 'accent');
    outline: 1.5px solid #9296fa;
  }

  &.is-connected > :nth-child(1) > :nth-child(1) {
    fill: color('icon', 'primary-inverted');
  }

  &.is-connected.vue-flow__handle-connecting {
    background-color: color('icon', 'success') !important;
  }

  &.is-valid {
    background-color: color('icon', 'small-success');
  }

  &.is-disabled {
    pointer-events: none;
    background-color: color('bg', 'box');
  }

  &.is-valid > :nth-child(1) > :nth-child(1) {
    fill: color('icon', 'primary-inverted');
  }
}

.vue-flow__handle-valid {
  background: color('icon', 'success') !important;
}

.vue-flow__handle:hover {
  background-color: color('icon', 'accent');
}

.vue-flow__handle-valid > :nth-child(1) > :nth-child(1),
.vue-flow__handle:hover > :nth-child(1) > :nth-child(1) {
  fill: color('icon', 'primary-inverted');
}
</style>
