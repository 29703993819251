<script setup lang="ts"></script>

<template>
  <UDivider
    orientation="vertical"
    class="h-16"
    :ui="{
      border: { base: 'border-primary-500' },
      container: { vertical: 'my-0.5' },
    }"
  >
    <UTooltip text="Add step to loop">
      <UButton icon="i-ph-plus" size="2xs" color="white" variant="solid" />
    </UTooltip>
  </UDivider>
</template>
