<script setup lang="ts">
import { typeMap } from '@respell/utils';

const modelValue = defineModel<string>();

defineProps<{
  size?: 'sm';
  hide?: string[];
}>();
</script>
<template>
  <USelectMenu
    v-model="modelValue"
    :size="size"
    value-attribute="key"
    :options="
      Object.values(typeMap).filter(
        (type) => type.key !== 'daterange' && !hide?.includes(type.key),
      )
    "
  >
    <template #label>
      <UIcon
        :name="typeMap[modelValue ?? 'text/plain'].icon"
        :class="size === 'sm' ? 'w-4 h-4' : 'w-5 h-5'"
      />
      <p :class="size === 'sm' ? 'body-sm' : 'body'">
        {{ typeMap[modelValue ?? 'text/plain'].name }}
      </p>
    </template>

    <template #option="{ option, selected }">
      <UIcon
        :name="option.icon"
        :class="[
          size === 'sm' ? 'w-4 h-4' : 'w-5 h-5',
          selected ? 'text-white' : 'text-gray-600',
        ]"
      />
      <p
        :class="[
          size === 'sm' ? 'body-sm' : 'body',
          selected ? 'text-white' : 'text-gray-600',
        ]"
      >
        {{ option.name }}
      </p>
    </template>
  </USelectMenu>
</template>
