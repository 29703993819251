<script setup lang="ts">
import type { Condition, Variable } from '@respell/utils';
import { conditionTypeOptions } from '@respell/utils/types';
import { useChangeCase } from '@vueuse/integrations/useChangeCase';
import AppDropDown from '~/components/app/AppDropdown.vue';
import AppInput from '~/components/app/AppInput.vue';
import { createOptionSchema } from '~/util/validation';
import StepWarning from './StepWarning.vue';

const canvasStore = useCanvasStore();
const { selectedPath, errors } = storeToRefs(canvasStore);
const { selectedStep } = useSelectedStep();

const pathSelection = computed<string | null>({
  get: () => selectedPath.value?.path ?? null,
  set: (newPath: string | null) => {
    selectedPath.value = selectedStep.value?.data.options.conditions.find(
      (condition: Condition) => condition.path === newPath,
    );
  },
});

const conditionOptions = computed(
  () =>
    selectedStep.value?.data.options.conditions.map(
      (condition: Condition, index: number) => ({
        key: condition.path,
        name: `${(index + 10).toString(36).toUpperCase()}. ${canvasStore.generatePathLabel(condition.path)}`,
      }),
    ) ?? [],
);

const issues = computed(() => errors.value?.[selectedStep.value?.data.slug]);

const noSelectedPath = computed(() => !selectedPath.value);

const conditionConfig: Record<string, Variable> = {
  value: {
    key: 'value',
    name: 'Follow this path if:',
    isOptional: false,
    type: 'text/plain',
    listDepth: 0,
    value: null,
  },
  type: {
    key: 'type',
    name: 'Type',
    isOptional: false,
    type: 'text/plain',
    listDepth: 0,
    metadata: {
      options: Object.values(conditionTypeOptions),
    },
    value: conditionTypeOptions.equals,
  },
  test: {
    key: 'test',
    name: 'Test',
    isOptional: false,
    type: 'text/plain',
    listDepth: 0,
    value: null,
  },
};

const schema = createOptionSchema(conditionConfig);

defineShortcuts({
  backspace: {
    whenever: [noSelectedPath],
    handler: () => canvasStore.removeStep(selectedStep.value?.data.slug),
  },
});
</script>
<template>
  <div
    class="w-full flex h-full justify-start max-h-full flex-col divide-y gap-4"
  >
    <div class="w-full">
      <transition name="fade">
        <StepWarning v-if="issues?.length" :errors="issues" />
      </transition>
      <UFormGroup size="xl" label="Path:" class="w-full mt-s">
        <AppDropDown
          v-model="pathSelection"
          :options="conditionOptions"
          class="w-full"
        />
      </UFormGroup>
    </div>
    <div
      v-if="
        selectedStep?.data.options.conditions.find(
          (c) => c.path === selectedPath?.path,
        )
      "
      class="w-full py-4"
    >
      <p class="title">
        {{ useChangeCase(selectedPath?.path ?? 'Path', 'capitalCase').value }}
      </p>

      <UForm
        :key="selectedPath?.path"
        :schema="schema"
        :state="
          selectedStep.data.options.conditions.find(
            (c: Condition) => c.path === selectedPath?.path,
          ).elements?.[0]
        "
        class="flex flex-col mt-s w-full gap-4 p-m bg-primary-50 shadow-sm rounded-xl border-primary-200 border items-start"
      >
        <UFormGroup
          size="xl"
          label="AI Mode"
          description="Describe this condition using plain english"
          class="mt-s"
        >
          <UToggle
            v-model="
              selectedStep.data.options.conditions.find(
                (c: Condition) => c.path === selectedPath?.path,
              ).elements[0].aiMode
            "
          />
        </UFormGroup>

        <AppInput
          :key="selectedPath?.path + '.value'"
          v-model="
            selectedStep.data.options.conditions.find(
              (c: Condition) => c.path === selectedPath?.path,
            ).elements[0].value
          "
          :option="conditionConfig.value"
          class="w-full relative"
          injectable
        />
        <div
          v-if="
            !selectedStep.data.options.conditions.find(
              (c: Condition) => c.path === selectedPath?.path,
            ).elements[0].aiMode
          "
          class="contents"
        >
          <AppInput
            :key="selectedPath?.path + '.type'"
            v-model="
              selectedStep.data.options.conditions.find(
                (c: Condition) => c.path === selectedPath?.path,
              ).elements[0].type
            "
            :option="conditionConfig.type"
            hide-label
            class="w-full relative"
          />
          <AppInput
            :key="selectedPath?.path + '.test'"
            v-model="
              selectedStep.data.options.conditions.find(
                (c: Condition) => c.path === selectedPath?.path,
              ).elements[0].test
            "
            :option="conditionConfig.test"
            hide-label
            class="w-full relative"
            injectable
          />
        </div>
      </UForm>
    </div>
  </div>
</template>
