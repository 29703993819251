<script setup lang="ts">
import type { NodeProps } from '@vue-flow/core';
import StartNode from './StartNode.vue';

const props = defineProps<NodeProps>();
</script>

<template>
  <StartNode v-bind="props" />
</template>
