<script setup lang="ts">
import definitions from '@respell/steps';
import type { NodeProps } from '@vue-flow/core';
import {
  Handle,
  Position,
  useHandleConnections,
  useVueFlow,
} from '@vue-flow/core';
import AddLoopStepButton from './AddLoopStepButton.vue';
import AddStepButton from './AddStepButton.vue';
import AddStepNode from './AddStepNode.vue';
import NodeCard from './NodeCard.vue';
import StepNode from './StepNode.vue';

const props = defineProps<NodeProps>();
const canvasStore = useCanvasStore();
const { elements } = storeToRefs(canvasStore);
const { updateEdgeData } = useVueFlow({
  id: 'editor',
});

const loop = reactiveComputed(() => {
  return {
    sequence:
      props.data?.options?.sequence?.map((slug) =>
        elements.value.find((el) => el.data?.slug === slug),
      ) ?? [],
  };
});

const activeTargets = useHandleConnections({
  type: 'target',
});

const allSources = useHandleConnections({
  type: 'source',
  onConnect: (params) => {
    const newConnection = params[0];
    setTimeout(() => {
      if (
        !loop.sequence?.map((step) => step.id).includes(newConnection.target)
      ) {
        updateEdgeData(newConnection.edgeId, {
          loopNodeId: props.id,
        });
      }
    }, 400);
  },
});

const hasActiveSources = computed(() =>
  allSources.value?.some(
    (source) => !loop.sequence?.map((step) => step.id).includes(source.target),
  ),
);

const handleAddStep = (index: number) => {
  const tempAddStepNode = canvasStore.toggleAddStep({
    source: index === 0 ? props.id : loop.sequence[index - 1]?.id,
    loopIndex: index,
    loopNodeId: props.id,
  });

  if (tempAddStepNode) {
    setTimeout(() => {
      const addStepNode = canvasStore.elements.find(
        (el) => el.id === tempAddStepNode.id,
      );
      loop.sequence.splice(index, 0, addStepNode);
    }, 200);
  }
};

const handleRemoveStep = (index: number) => {
  loop.sequence.splice(index, 1);
};
</script>

<template>
  <Handle
    type="target"
    :position="Position.Left"
    class="handle"
    :class="{ 'is-connected': activeTargets.length }"
  />

  <Handle
    type="source"
    :position="Position.Right"
    class="handle"
    :class="{ 'is-connected': hasActiveSources }"
  />

  <div
    v-if="!hasActiveSources"
    class="absolute -top-[20px] -right-[80px] h-24 mr-s flex flex-row max-w-16 items-center"
    @click.stop
  >
    <div class="w-12 h-[1px] shrink bg-gray-300 rounded-full" />
    <AddStepButton :source="props.id" type="step" />
  </div>

  <NodeCard v-bind="props" node-type="loop" :step-type="definitions.loop">
    <template #footer>
      <div class="flex flex-col w-full bg-primary-50 rounded-b-2xl">
        <AddLoopStepButton @click.stop="() => handleAddStep(0)" />
        <span
          v-for="(step, index) in loop.sequence"
          :key="step.id"
          class="contents"
        >
          <StepNode v-if="step.type === 'step'" v-bind="step" in-loop />
          <AddStepNode
            v-else-if="step.type === 'add-step'"
            v-bind="step"
            @remove="handleRemoveStep(index)"
          />
          <AddLoopStepButton @click.stop="() => handleAddStep(index + 1)" />
        </span>
      </div>
    </template>
  </NodeCard>
</template>
<style lang="scss" scoped>
.handle {
  width: 16px;
  height: 16px;
}
</style>
