<script setup lang="ts">
import type { Variable } from '@respell/utils';
import TypePicker from '~/components/editor/config/TypePicker.vue';

const modelValue = defineModel<Variable[]>();

const addEntry = () => {
  modelValue.value?.push({
    key: '',
    type: 'text/plain',
    listDepth: 0,
    isOptional: false,
    value: null,
    name: '',
  });
};

const removeEntry = (index: number) => {
  modelValue.value?.splice(index, 1);
};
</script>
<template>
  <div class="flex flex-col items-center gap-3 pt-s">
    <div
      v-for="(entry, index) in modelValue"
      :key="index"
      class="flex flex-col w-full items-start gap-2"
    >
      <span class="flex gap-2 items-center w-full">
        <UInput
          v-model="entry.name"
          placeholder="Name this entry"
          color="white"
          size="lg"
          class="grow"
        />
        <TypePicker v-model="entry.type" :hide="['object']" />
      </span>

      <UButton
        variant="link"
        size="xs"
        :padded="false"
        icon="i-ph-trash-simple"
        color="gray"
        label="Remove"
        @click="() => removeEntry(index)"
      />
    </div>

    <UButton
      icon="i-ph-plus-bold"
      size="md"
      color="gray"
      variant="ghost"
      class="border border-gray-200 border-dashed text-gray-400"
      label="Add Entry"
      block
      :ui="{
        size: { lg: 'text-lg' },
        icon: { size: { lg: 'h-5 w-5' } },
      }"
      @click="addEntry"
    />
  </div>
</template>
