export function useHistory() {
  const canvasStore = useCanvasStore();
  const { elements } = storeToRefs(canvasStore);

  const history = useDebouncedRefHistory(elements, {
    deep: true,
    debounce: 500,
  });

  return {
    history,
  };
}
