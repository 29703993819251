<!-- https://vueflow.dev/examples/dnd.html -->
<script lang="ts" setup>
import { Background } from '@vue-flow/background';
</script>

<template>
  <div style="height: 100%; width: 100%">
    <Background pattern-color="#787893" :gap="20" :size="1.5">
      <slot />
    </Background>
  </div>
</template>
