import type { DataType, Json } from '@respell/utils';
import { useVueFlow } from '@vue-flow/core';
import { defineStore, storeToRefs } from 'pinia';
import { useCanvasStore } from './canvas';

interface SpellInput {
  key: string;
  name: string;
  isOptional: boolean;
  type: DataType;
  listDepth: number;
  value: Json;
}

interface SpellOutput extends SpellInput {}

interface SpellStep {
  key: string;
  options?: Record<string, Json>;
}

interface GenerateResponse {
  trigger?: {
    service: string;
    event: string;
  };
  start_inputs?: SpellInput[];
  steps: SpellStep[];
  display_outputs?: SpellOutput[];
}

// Store for managing spell generation and canvas manipulation
export const useSpellcasterStore = defineStore('spellcaster', () => {
  // Get access to canvas store and its methods
  const canvasStore = useCanvasStore();
  const {
    addStep,
    updateStep,
    addEdge,
    saveCanvas,
    addVariable,
    selectStep,
    deselectSteps,
    cleanupStartElements,
    cleanupSpellcasterElements,
    generateKey,
  } = canvasStore;

  const userStore = useUserStore();
  const { user } = storeToRefs(userStore);

  const spellsStore = useSpellsStore();
  const { spell } = storeToRefs(spellsStore);

  // Initialize Vue Flow with editor ID
  const { fitView } = useVueFlow({
    id: 'editor',
  });

  // Get reactive refs to canvas store state
  const { graph } = storeToRefs(canvasStore);

  /**
   * Generates a complete spell workflow from a natural language prompt
   * @param prompt - Natural language description of desired workflow
   * @returns The generated graph configuration
   */
  async function generateSpell(prompt: string) {
    // Remove any existing start elements before generating new spell
    cleanupStartElements();

    // Center view on the graph after a short delay
    setTimeout(() => {
      fitView({ duration: 200 });
    }, 300);

    // Call API to generate spell configuration
    const response = await $api<GenerateResponse>('/api/spells/generate', {
      method: 'post',
      body: { prompt, spellId: spell.value?.id },
    });

    const { trigger, steps, start_inputs, display_outputs } = response;

    // Calculate positioning for visual layout
    const centerX = window.innerWidth / 2;
    const centerY = window.innerHeight / 2;
    const startY = centerY - 122; // Offset for spellcaster height
    const stepXOffset = 400; // Horizontal spacing between steps

    // Clean existing spellcaster elements
    cleanupSpellcasterElements();

    // Add initial step - either trigger or start node
    let lastStep = addStep(trigger ? 'trigger' : 'start', {
      x: centerX,
      y: startY,
    });
    await saveCanvas();

    // Configure either trigger settings or input variables
    if (trigger) {
      canvasStore.trigger = trigger;
      await saveCanvas();
    } else if (start_inputs?.length) {
      // Add all input variables in parallel
      await Promise.all(
        start_inputs.map((input: SpellInput) =>
          addVariable(
            {
              key: generateKey(input.key, 'input'),
              name: input.name,
              isOptional: input.isOptional,
              type: input.type,
              listDepth: input.listDepth,
              value: input.value,
            },
            'input',
          ),
        ),
      );
      await saveCanvas();
    }

    // Process and add remaining workflow steps
    const remainingSteps = steps.filter((s: SpellStep) => s.key !== 'start');
    let xOffset = centerX + stepXOffset;

    // Iterate through steps to build workflow
    for (const step of remainingSteps) {
      // Create new step and connect to previous
      const newStep = addStep(step.key, { x: xOffset, y: startY });
      addEdge({
        source: lastStep.id,
        target: newStep.id,
        type: 'step',
      });

      // Configure step settings
      await updateStep(newStep.id, {
        key: step.key,
        options: step.options,
      });
      await saveCanvas();

      // Handle special case for display step with outputs
      if (step.key === 'display' && display_outputs?.length) {
        selectStep(newStep.id);
        // Add all output variables in parallel
        await Promise.all(
          display_outputs.map((output: SpellOutput) =>
            addVariable(
              {
                key: generateKey(output.key, 'output'),
                name: output.name,
                isOptional: output.isOptional,
                type: output.type,
                listDepth: output.listDepth,
                value: output.value,
              },
              'output',
            ),
          ),
        );
        deselectSteps();
        await saveCanvas();
      }

      // Update for next iteration
      lastStep = newStep;
      xOffset += stepXOffset;
    }

    // Create generation and graph record for this spell generation
    await $api('/api/generations', {
      method: 'post',
      body: {
        prompt,
        spellId: graph.value?.spellId,
        userId: user.value?.id,
      },
    });

    return graph.value;
  }

  return { generateSpell };
});
