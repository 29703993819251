<template>
  <div
    id="add-start-node"
    class="w-96 h-48 cursor-pointer radius-m bg-primary-50 flex flex-col items-center justify-center space-y-2"
    :class="
      selected
        ? 'border-container-active shadow-node-active'
        : 'border-dashed border-purple-300 border hover:shadow-select'
    "
  >
    <div class="flex p-s radius-s border border-primary-200">
      <UIcon
        name="i-ph-plus-bold"
        class="text-primary-500 stroke-primary-500"
      />
    </div>
    <p class="text-sm font-bold text-primary-500">
      {{ isEnabled('spellcaster') ? 'Start from scratch' : 'Add Start' }}
    </p>
  </div>
</template>
<script setup lang="ts">
import type { NodeProps } from '@vue-flow/core';
const { selected } = defineProps<NodeProps>();
const userStore = useUserStore();
const { isEnabled } = userStore;
</script>
