<script setup lang="ts">
import type { NodeProps } from '@vue-flow/core';
import StepNode from '~/components/canvas/StepNode.vue';

const props = defineProps<NodeProps>();
</script>

<template>
  <StepNode v-bind="props" />
</template>
