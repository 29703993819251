<script setup lang="ts">
import type { NodeProps } from '@vue-flow/core';
import AppStepLabel from '~/components/app/AppStepLabel.vue';
import StepNode from '~/components/canvas/StepNode.vue';

const props = defineProps<NodeProps>();

const waitTime = computed(() => {
  const amount = props.data.options.amount;
  const units = props.data.options.units;
  return `${amount} ${amount === 1 ? units.slice(0, -1) : units}`;
});
</script>

<template>
  <StepNode v-bind="props">
    <template #label>
      <AppStepLabel :label="label" step-name="wait" size="xs" wrap />
      <UBadge color="gray" variant="soft" :ui="{ rounded: 'rounded-2xl' }">
        {{ waitTime }}
      </UBadge>
    </template>
  </StepNode>
</template>
