<script setup lang="ts">
import definitions from '@respell/steps';
import AppInput from '~/components/app/AppInput.vue';
const { selectedStep } = useSelectedStep();
</script>
<template>
  <AppInput
    v-model="selectedStep.data.options.iterable"
    :option="definitions.loop.options.iterable"
    injectable
  />
</template>
