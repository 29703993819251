<template>
  <UButton
    :id="type + '-add-step-button'"
    icon="i-ph-plus"
    size="2xs"
    variant="solid"
    class="rounded-full bg-gray-300 hover:bg-primary-400 transition-all hover:scale-110"
    @click.stop="handleClick"
  />
</template>
<script setup lang="ts">
const canvasStore = useCanvasStore();
const { source, sourceHandle, target } = defineProps<{
  source: string;
  type: string;
  sourceHandle?: string;
  target?: string;
}>();

const handleClick = () => {
  canvasStore.toggleAddStep({ source, sourceHandle, target });
};
</script>
