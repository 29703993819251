<script setup lang="ts">
import type { Form } from '#ui/types';
import definitions from '@respell/steps';
import type { Variable } from '@respell/utils';
import StepWarning from '~/components/editor/config/StepWarning.vue';
import { createOptionSchema } from '~/util/validation';
const canvasStore = useCanvasStore();
const { selectedStep } = useSelectedStep();

const waitDefinition = definitions.wait;

const form = ref<Form<Variable> | null>(null);

const schema = createOptionSchema(waitDefinition.options);

defineShortcuts({
  backspace: {
    handler: () => {
      canvasStore.removeStep(selectedStep.value?.data.slug as string);
    },
  },
});
</script>
<template>
  <UForm
    ref="form"
    :schema="schema"
    :state="selectedStep.data.options"
    class="grow pb-48"
  >
    <transition name="fade">
      <span v-if="form?.errors?.length" class="flex">
        <StepWarning :errors="form?.errors" :options="waitDefinition.options" />
      </span>
    </transition>

    <UFormGroup
      label="Wait time"
      description="How long the step should wait before being marked as complete and continuing forward."
      size="xl"
    />
    <div class="flex gap-2 items-start">
      <AppInput
        v-model="selectedStep.data.options.amount"
        :option="waitDefinition.options.amount"
        hide-label
      />

      <AppInput
        v-model="selectedStep.data.options.units"
        :option="waitDefinition.options.units"
        hide-label
      />
    </div>
  </UForm>
</template>
