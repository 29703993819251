<script setup lang="ts">
import type { EdgeProps } from '@vue-flow/core';
import StepEdge from './StepEdge.vue';

const props = defineProps<EdgeProps>();
</script>

<template>
  <StepEdge v-bind="props" />
</template>
