<script setup lang="ts">
import type { Condition } from '@respell/utils';
import { conditionTypeOptions } from '@respell/utils/types';
import type { Connection, GraphEdge } from '@vue-flow/core';
import {
  Handle,
  Position,
  useHandleConnections,
  useNodesData,
  useVueFlow,
} from '@vue-flow/core';
import VariableNode from '~/components/app/VariableNode.vue';
import AddStepButton from '~/components/canvas/AddStepButton.vue';

const props = defineProps<{
  condition: Condition;
  stepId: string;
  index: number;
}>();
const canvasStore = useCanvasStore();
const { selectedPath } = storeToRefs(canvasStore);
const { selectedStep } = useSelectedStep();

const element = computed(() => props.condition.elements?.[0]);
const isSelected = computed(
  () =>
    selectedPath.value?.path === props.condition.path &&
    props.stepId === selectedStep.value?.id,
);

const node = useNodesData(props.stepId);
const { updateNodeData, removeEdges } = useVueFlow({ id: 'editor' });

const label = computed(() => {
  return canvasStore.generatePathLabel(props.condition.path);
});

const activeSources = useHandleConnections({
  nodeId: props.stepId,
  type: 'source',
  id: props.condition.path,
});

// Reset the path key if an edge is removed
watch(activeSources, () => {
  if (
    !activeSources.value.length &&
    node.value?.data.options.conditions.length
  ) {
    node.value.data.options.conditions.find(
      (condition: Condition) => condition.path === props.condition.path,
    ).path = canvasStore.generatePathKey(node.value?.data.options.conditions);
  }
});

function parseText(text: string) {
  const result = [];
  let lastIndex = 0;

  text.replace(globalVariableRegex, (match, p1, p2, p3, p4, p5, offset) => {
    // Add text before the match
    if (offset > lastIndex) {
      result.push({ text: text.slice(lastIndex, offset) });
    }
    // Add the variable match
    result.push({ variable: match });
    // Update the lastIndex to the end of the current match
    lastIndex = offset + match.length;
  });

  // Add any remaining text after the last match
  if (lastIndex < text.length) {
    result.push({ text: text.slice(lastIndex) });
  }

  return result;
}

const parsedValue = computed(() =>
  element.value?.value ? parseText(element.value.value) : [{}],
);

const parsedTest = computed(() =>
  element.value?.test ? parseText(element.value.test) : [{}],
);

function handleSelect() {
  selectedPath.value = props.condition;
}

function deletePath() {
  const pathIndex = useFindIndex(
    node.value?.data.options.conditions,
    (c) => c.path === props.condition.path,
  );

  const updatedConditions = [...node.value?.data.options.conditions];
  updatedConditions.splice(pathIndex, 1);

  removeEdges(
    activeSources.value.map((connection: Connection) => connection.edgeId),
  );
  updateNodeData(props.stepId, {
    options: {
      conditions: updatedConditions,
    },
  });
  setTimeout(() => {
    if (pathIndex === 0) {
      selectedPath.value = node.value?.data.options.conditions[0];
    } else {
      selectedPath.value = node.value?.data.options.conditions[pathIndex - 1];
    }
  }, 0);
}

defineShortcuts({
  backspace: {
    whenever: [isSelected],
    handler: () => deletePath(),
  },
});

function checkValidConnection(connection: Connection, elements) {
  return (
    !activeSources.value.length &&
    !elements.edges.some(
      (edge: GraphEdge) =>
        edge.sourceHandle === connection.sourceHandle &&
        edge.target === connection.target,
    )
  );
}
</script>
<template>
  <div class="flex gap-2 w-full">
    <transition name="fade">
      <UButton
        v-if="isSelected"
        variant="link"
        size="sm"
        :padded="false"
        icon="i-ph-trash-simple"
        color="gray"
        @click="deletePath"
      />
    </transition>
    <div
      class="flex flex-col w-full relative bg-white gap-2 p-2 transition-all rounded-md items-start"
      :class="
        isSelected
          ? 'border-container-active shadow-node-active'
          : 'border-container shadow-select-hover'
      "
      @click="handleSelect"
    >
      <Handle
        :id="condition.path"
        type="source"
        :position="Position.Right"
        class="handle"
        :is-valid-connection="checkValidConnection"
        :class="{
          'is-connected': activeSources.length,
        }"
      />

      <div
        v-if="!activeSources.length"
        class="absolute -top-[20px] -right-1/4 pl-1.5 h-24 mr-s flex flex-row max-w-16 items-center"
        @click.stop
      >
        <div class="w-12 h-[1px] shrink bg-gray-300 rounded-full" />
        <AddStepButton
          :source="stepId"
          :source-handle="condition.path"
          type="condition"
        />
      </div>

      <span class="flex flex-row gap-1">
        <p v-if="!label.includes('Path')" class="body text-gray-400">Go to</p>
        <p class="body text-gray-700">{{ label }}</p>
      </span>
      <div class="flex flex-row gap-1 flex-wrap justify-start">
        <p class="body-sm text-gray-400">If</p>
        <span v-for="(part, idx) in parsedValue" :key="idx" class="contents">
          <VariableNode
            v-if="part.variable"
            v-model="part.variable"
            size="2xs"
            readonly
          />
          <p v-else class="body-sm text-gray-800">
            {{ part.text ?? '<empty />' }}
          </p>
        </span>
        <p v-if="!element?.aiMode" class="body-sm text-gray-400">
          {{ element?.type ? conditionTypeOptions[element.type].name : 'is' }}
        </p>
        <span v-for="(part, idx) in parsedTest" :key="idx" class="contents">
          <VariableNode
            v-if="part.variable"
            v-model="part.variable"
            size="2xs"
            readonly
          />
          <p v-else-if="!element?.aiMode" class="body-sm text-gray-800">
            {{ part.text ?? '<empty />' }}
          </p>
        </span>
      </div>
    </div>
  </div>
</template>
