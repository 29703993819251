<script setup lang="ts">
import type { Variable } from '@respell/utils';
import AppInput from '~/components/app/AppInput.vue';
import ContextInput from '~/components/editor/config/ContextInput.vue';

const modelValue = defineModel<any>();
const spellStore = useSpellsStore();
const { spell } = storeToRefs(spellStore);

defineProps<{
  option: Variable;
  hasError: boolean;
  hasContext: boolean;
}>();
</script>

<template>
  <ContextInput
    v-if="hasContext"
    v-model="modelValue"
    :option="option"
    class="mb-l"
    owner-type="Spell"
    :owner-id="spell?.id"
    :has-error="hasError"
    injectable
  />
  <AppInput
    v-else
    v-model="modelValue"
    :option="option"
    class="mb-l"
    :has-error="hasError"
    owner-type="Spell"
    :owner-id="spell?.id"
    injectable
  />
</template>
