<script setup lang="ts">
import { useVueFlow } from '@vue-flow/core';
import ConfirmModal from '../modals/ConfirmModal.vue';

const canvasStore = useCanvasStore();
const tutorialStore = useTutorialStore();

const { history } = useHistory();
const modal = useModal();
const { undo, redo, canUndo, canRedo } = history;
const { zoomIn, zoomOut, getViewport, fitView } = useVueFlow({
  id: 'editor',
});

const currentZoom = computed(() => getViewport().zoom);

const tutorialTip = computed(() => {
  return tutorialStore.state === 'active'
    ? 'Hide tutorial'
    : tutorialStore.state === 'inactive'
      ? 'Start tutorial'
      : 'Resume tutorial';
});

const handleTutorial = () => {
  if (tutorialStore.state === 'active') {
    tutorialStore.state = 'hidden';
  } else if (tutorialStore.state === 'inactive') {
    modal.open(ConfirmModal, {
      action: 'Restart',
      type: 'Tutorial',
      message:
        "You'll need a blank canvas for this tutorial, so we'll create a new spell for you. Your current spell will be saved.",
      isDangerous: false,
      async onConfirm() {
        await tutorialStore.restartTutorial();
      },
    });
  } else if (tutorialStore.state === 'hidden') {
    tutorialStore.state = 'active';
  }
};

defineShortcuts({
  ' ': {
    handler: () => fitView({ duration: 250 }),
  },
  '+': {
    handler: () => zoomIn({ duration: 250 }),
  },
  '=': {
    handler: () => zoomIn({ duration: 250 }),
  },
  '-': {
    handler: () => zoomOut({ duration: 250 }),
  },
  _: {
    handler: () => zoomOut({ duration: 250 }),
  },
  meta_z: {
    whenever: [canUndo],
    handler: () => undo(),
  },
  meta_shift_z: {
    whenever: [canRedo],
    handler: () => redo(),
  },
});
</script>
<template>
  <div
    class="flex border-container rounded-md shadow-select bg-container p-s divide-x"
  >
    <span class="flex pr-2">
      <UTooltip text="Zoom out" :shortcuts="['-']">
        <UButton
          icon="i-ph-minus"
          size="2xs"
          color="gray"
          @click="
            () =>
              zoomOut({
                duration: 250,
              })
          "
        />
      </UTooltip>
      <p class="caption-2 mx-s dimmed tabular-numbers">
        {{ Math.round(currentZoom * 100) }}%
      </p>
      <UTooltip text="Zoom in" :shortcuts="['+']">
        <UButton
          icon="i-ph-plus"
          size="2xs"
          color="gray"
          @click="
            () =>
              zoomIn({
                duration: 250,
              })
          "
        />
      </UTooltip>
    </span>

    <span class="flex px-2 gap-2">
      <UTooltip text="Undo" :shortcuts="['⌘', 'Z']">
        <UButton
          icon="i-ph-arrow-u-up-left"
          :disabled="!canUndo"
          size="sm"
          :padded="false"
          color="gray"
          variant="link"
          @click="undo"
        />
      </UTooltip>

      <UTooltip text="Redo" :shortcuts="['⌘', 'Shift', 'Z']">
        <UButton
          icon="i-ph-arrow-u-up-right"
          :disabled="!canRedo"
          size="sm"
          :padded="false"
          color="gray"
          variant="link"
          @click="redo"
        />
      </UTooltip>
    </span>

    <UTooltip text="Fit to view" :shortcuts="['Space']" class="px-2">
      <UButton
        icon="i-ph-corners-out"
        size="sm"
        :padded="false"
        color="gray"
        variant="link"
        @click="() => fitView({ duration: 250 })"
      />
    </UTooltip>

    <UTooltip text="Add a note" class="px-2">
      <UButton
        icon="i-ph-note-blank"
        size="sm"
        :padded="false"
        color="gray"
        variant="link"
        @click="canvasStore.addNote"
      />
    </UTooltip>

    <UTooltip :text="tutorialTip" class="pl-2">
      <UChip :show="tutorialStore.state === 'hidden'" color="yellow">
        <UButton
          icon="i-ph-question"
          size="sm"
          :padded="false"
          color="gray"
          variant="link"
          @click="handleTutorial"
        />
      </UChip>
    </UTooltip>
  </div>
</template>
