<script setup lang="ts">
import CharacterCount from '@tiptap/extension-character-count';
import Highlight from '@tiptap/extension-highlight';
import Placeholder from '@tiptap/extension-placeholder';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import StarterKit from '@tiptap/starter-kit';
import { EditorContent, useEditor } from '@tiptap/vue-3';
import type { NodeProps } from '@vue-flow/core';
import { useNode } from '@vue-flow/core';
import { NodeResizer } from '@vue-flow/node-resizer';
import { NodeToolbar } from '@vue-flow/node-toolbar';

const props = defineProps<NodeProps>();

const { node } = useNode();
const canvasStore = useCanvasStore();

const dims = ref({
  width: props.dimensions.width,
  height: props.dimensions.height,
});

const editor = useEditor({
  content: node.data.content,
  extensions: [
    StarterKit.configure(),
    Highlight,
    TaskList,
    TaskItem,
    Placeholder.configure({
      placeholder: 'Type your note here...',
    }),
    CharacterCount.configure({
      limit: 10000,
    }),
  ],
  onUpdate: () => {
    node.data.content = editor.value?.getHTML() as string;
  },
});

watchImmediate(
  () => props.selected,
  (selected) => {
    if (selected) {
      editor.value?.chain().focus();
    }
  },
);

const actions = computed(() => {
  return [
    {
      icon: 'i-ph-text-b-bold',
      title: 'Bold',
      click: () => editor.value?.chain().focus().toggleBold().run(),
      isActive: editor.value?.isActive('bold'),
    },
    {
      icon: 'i-ph-text-italic',
      title: 'Italic',
      click: () => editor.value?.chain().focus().toggleItalic().run(),
      isActive: editor.value?.isActive('italic'),
    },
    {
      icon: 'i-ph-text-strikethrough',
      title: 'Strike',
      click: () => editor.value?.chain().focus().toggleStrike().run(),
      isActive: editor.value?.isActive('strike'),
    },
    {
      icon: 'i-ph-highlighter',
      title: 'Highlight',
      click: () => editor.value?.chain().focus().toggleHighlight().run(),
      isActive: editor.value?.isActive('highlight'),
    },
    {
      icon: 'i-ph-text-h-one',
      title: 'Heading 1',
      click: () =>
        editor.value?.chain().focus().toggleHeading({ level: 1 }).run(),
      isActive: editor.value?.isActive('heading', { level: 1 }),
    },
    {
      icon: 'i-ph-text-h-two',
      title: 'Heading 2',
      click: () =>
        editor.value?.chain().focus().toggleHeading({ level: 2 }).run(),
      isActive: editor.value?.isActive('heading', { level: 2 }),
    },
    {
      icon: 'i-ph-list-bullets',
      title: 'Bullet List',
      click: () => editor.value?.chain().focus().toggleBulletList().run(),
      isActive: editor.value?.isActive('bulletList'),
    },
    {
      icon: 'i-ph-list-numbers',
      title: 'Ordered List',
      click: () => editor.value?.chain().focus().toggleOrderedList().run(),
      isActive: editor.value?.isActive('orderedList'),
    },
    {
      icon: 'i-ph-list-checks',
      title: 'Task List',
      click: () => editor.value?.chain().focus().toggleTaskList().run(),
      isActive: editor.value?.isActive('taskList'),
    },
  ];
});

const onResize = (event) => {
  dims.value = { width: event.params.width, height: event.params.height };
};
</script>
<template>
  <NodeToolbar
    :is-visible="data.toolbarVisible"
    :position="data.toolbarPosition"
    class="border-container bg-white flex gap-1 py-1 px-2 rounded-xl"
  >
    <UTooltip
      v-for="action of actions"
      :key="action.title"
      :text="action.title"
    >
      <UButton
        :icon="action.icon"
        :color="action.isActive ? 'primary' : 'white'"
        :variant="action.isActive ? 'solid' : 'none'"
        size="sm"
        @click="action.click"
      />
    </UTooltip>
    <UDivider orientation="vertical" class="h-5" />
    <UTooltip text="Delete">
      <UButton
        icon="i-ph-trash-simple"
        color="gray"
        variant="none"
        size="sm"
        @click="() => canvasStore.removeNote(props.id)"
      />
    </UTooltip>
  </NodeToolbar>
  <NodeResizer
    v-if="selected"
    :min-width="300"
    :min-height="200"
    @resize="onResize"
  />
  <div
    class="rounded-2xl bg-container p-5 h-full w-full overflow-hidden"
    :class="
      selected
        ? 'border-container-active shadow-node-active cursor-text'
        : 'border-container shadow-select-hover cursor-pointer'
    "
    :style="{
      width: `${dims.width}px`,
      height: `${dims.height}px`,
      minWidth: '300px',
      minHeight: '200px',
    }"
  >
    <editor-content
      :class="{ 'nodrag nopan': selected }"
      :editor="editor"
      class="prose prose-lg prose-p:my-0.5 prose-ul:my-0.5"
    />
  </div>
</template>
<style lang="scss">
/* Basic editor styles */
.tiptap {
  :first-child {
    margin-top: 0;
  }

  /* List styles */
  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;

    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  /* Task list specific styles */
  ul[data-type='taskList'] {
    list-style: none;
    margin-left: 0;
    padding: 0;

    li {
      align-items: flex-start;
      display: flex;

      > label {
        flex: 0 0 auto;
        margin-right: 0.5rem;
        user-select: none;
      }

      > div {
        flex: 1 1 auto;
      }
    }

    input[type='checkbox'] {
      cursor: pointer;
    }

    ul[data-type='taskList'] {
      margin: 0;
    }
  }
}

.vue-flow__resize-control {
  position: absolute;

  &.left,
  &.right {
    cursor: ew-resize;
  }

  &.top,
  &.bottom {
    cursor: ns-resize;
  }

  &.top.left,
  &.bottom.right {
    cursor: nwse-resize;
  }

  &.bottom.left,
  &.top.right {
    cursor: nesw-resize;
  }

  /* handle styles */
  &.handle {
    width: 8px;
    height: 8px;
    border: 2px solid #fff;
    border-radius: 50%;
    background-color: #3367d9;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    transform: translate(-50%, -50%);
    transition: all 0.2s ease;

    &:hover {
      width: 10px;
      height: 10px;
      background-color: #4a7dff;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &.left {
      left: 0;
      top: 50%;
    }
    &.right {
      left: 100%;
      top: 50%;
    }
    &.top {
      left: 50%;
      top: 0;
    }
    &.bottom {
      left: 50%;
      top: 100%;
    }
    &.top.left {
      left: 0;
    }
    &.bottom.left {
      left: 0;
    }
    &.top.right {
      left: 100%;
    }
    &.bottom.right {
      left: 100%;
    }
  }
}
</style>
