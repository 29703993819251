<script setup lang="ts">
import type { NodeProps } from '@vue-flow/core';
import { computed } from 'vue';

const props = defineProps<NodeProps>();

const width = computed(() => props.data?.width || 800);
const height = computed(() => props.data?.height || 1);
const halfWidth = computed(() => width.value / 2);
</script>

<template>
  <div
    id="start-separator-node"
    class="flex items-center justify-center w-full"
    :style="{
      width: `${width}px`,
      height: `${height}px`,
    }"
  >
    <div class="bg-gray-400 h-full" :style="{ width: `${halfWidth - 20}px` }" />
    <div class="mx-2 text-xs text-gray-500">or</div>
    <div class="bg-gray-400 h-full" :style="{ width: `${halfWidth - 20}px` }" />
  </div>
</template>

<style scoped>
#start-separator-node {
  pointer-events: none;
}
</style>
