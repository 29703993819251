<script setup lang="ts">
import definitions from '@respell/steps';
import type { NodeProps } from '@vue-flow/core';
import {
  Handle,
  Position,
  useHandleConnections,
  useVueFlow,
} from '@vue-flow/core';
import PathCard from '~/components/editor/config/PathCard.vue';
import NodeCard from './NodeCard.vue';

const props = defineProps<NodeProps>();
const stepType = definitions.condition;

const { updateNodeData } = useVueFlow({ id: 'editor' });
const canvasStore = useCanvasStore();
const { selectedPath } = storeToRefs(canvasStore);
const { selectedStep } = useSelectedStep();

const activeTargets = useHandleConnections({
  nodeId: props.id,
  type: 'target',
});

const conditions = computed(() => props.data.options.conditions ?? []);

function handleAddPath() {
  const newCondition = {
    path: canvasStore.generatePathKey(conditions.value),
    elements: [{ type: 'equals' }],
    and: [0],
  };

  updateNodeData(props.id, {
    options: { conditions: [...conditions.value, newCondition] },
  });

  selectedPath.value = newCondition;
}

watch(selectedStep, (newStep, oldStep) => {
  if (
    oldStep?.id === props.id &&
    newStep?.id !== props.id &&
    newStep?.type !== 'condition'
  ) {
    selectedPath.value = null;
  }
});

watch(activeTargets, () => {
  canvasStore.validateStep(props.data.slug, props.data.key, props.data.options);
});
</script>

<template>
  <Handle
    type="target"
    :position="Position.Left"
    class="handle"
    :class="{ 'is-connected': activeTargets.length }"
  />

  <NodeCard v-bind="props" node-type="condition" :step-type="stepType">
    <template #footer>
      <div class="flex flex-col p-2 gap-2 w-full bg-primary-50 rounded-b-2xl">
        <PathCard
          v-for="(condition, index) in conditions"
          :key="condition.path"
          :condition="condition"
          :step-id="props.id"
          :index="index"
        />
        <UButton
          icon="i-ph-plus-bold"
          size="md"
          variant="ghost"
          class="border border-gray-300 border-dashed text-gray-400"
          :class="{ 'h-24': !conditions.length }"
          label="Add Path"
          block
          :ui="{
            size: { lg: 'text-lg' },
            icon: { size: { lg: 'h-5 w-5' } },
          }"
          @click="handleAddPath"
        />
      </div>
    </template>
  </NodeCard>
</template>
<style lang="scss" scoped>
.handle {
  width: 16px;
  height: 16px;
}
</style>
