<script setup lang="ts">
import integrations from '@respell/integrations';
import type { StepDefinition } from '@respell/steps/types';
import { useVueFlow, type NodeProps } from '@vue-flow/core';
import AppStepLabel from '~/components/app/AppStepLabel.vue';
import AppVariableTag from '~/components/app/AppVariableTag.vue';

const canvasStore = useCanvasStore();
const { addSelectedNodes } = useVueFlow({
  id: 'editor',
});

const { triggerDefinition, trigger, triggerReady, errors } =
  storeToRefs(canvasStore);

const props = defineProps<
  NodeProps & { stepType: StepDefinition; nodeType: string }
>();

const variables = computed(() =>
  canvasStore.fetchStepOutputs(props.stepType, props.id),
);

const isStart = computed(() => ['start', 'trigger'].includes(props.nodeType));

const issues = computed(() => errors.value[props.data?.slug]);
</script>
<template>
  <div
    class="rounded-2xl relative transition-all bg-container cursor-pointer overflow-visible divide-y flex flex-col justify-start"
    :class="[
      selected
        ? 'border-container-active shadow-node-active'
        : issues?.length
          ? 'border border-yellow-300 shadow-select-hover'
          : 'border-container shadow-select-hover',
      type === 'wait'
        ? 'h-auto w-auto'
        : type === 'loop'
          ? 'w-96 h-auto'
          : 'w-80 min-h-28',
    ]"
    @click.stop="() => addSelectedNodes([props])"
  >
    <UButton
      v-if="issues?.some((issue) => issue.path === 'Account')"
      icon="i-ph-warning-circle"
      size="xs"
      color="yellow"
      variant="solid"
      label="Connect your account"
      class="absolute -top-10 left-0"
      :ui="{
        variant: {
          solid: 'bg-yellow-100 text-yellow-700 hover:bg-yellow-200',
        },
      }"
      @click="() => addSelectedNodes([props])"
    />
    <div
      class="flex flex-row max-w-full justify-start rounded-t-2xl py-m px-m w-full gap-2"
      :class="{
        'bg-primary-100': isStart,
      }"
    >
      <UIcon
        :name="stepType.icon"
        class="text-2xl shrink-0"
        :class="
          nodeType === 'trigger'
            ? 'text-primary-500'
            : 'bg-white rounded-full border-gray-50 outline outline-gray-200 border'
        "
      />
      <slot name="label">
        <AppStepLabel
          :label="label"
          :step-name="stepType.name"
          size="xs"
          wrap
        />
      </slot>

      <UButton
        v-if="!isStart"
        :padded="false"
        color="gray"
        variant="link"
        icon="i-ph-x"
        class="ml-auto"
        @click="() => canvasStore.removeStep(data?.slug)"
      />
    </div>

    <div
      v-if="nodeType === 'trigger' && triggerReady"
      class="p-m w-full flex flex-row items-center justify-start gap-2"
    >
      <UIcon
        v-if="trigger?.service"
        :name="integrations[trigger?.service]?.icon"
        class="bg-white rounded-full border-gray-50 text-2xl outline outline-gray-200 border"
      />
      <p class="subtitle">{{ triggerDefinition?.name }}</p>
    </div>

    <slot v-if="type !== 'wait'" name="footer">
      <div class="flex flex-col p-m w-full justify-evenly gap-3 h-full">
        <div class="flex flex-row justify-start items-center w-full gap-2">
          <UIcon name="i-ph-diamonds-four" class="text-gray-400 text-2xl" />
          <p class="body dimmed">
            {{ nodeType === 'start' ? 'Inputs' : 'Outputs' }}
          </p>
        </div>
        <div
          class="flex flex-wrap gap-2 flex-row items-center justify-start w-full"
        >
          <AppVariableTag
            v-for="(variable, key) in variables"
            :key="key"
            :variable="variable"
            size="sm"
            :on-click="() => canvasStore.showVariableContext(id, variable)"
          />
        </div>
      </div>
    </slot>
  </div>
</template>
<style lang="scss">
.handle {
  width: 1rem !important;
  height: 1rem !important;
  background-color: #e7e7ef;
  outline: 1.5px solid #e7e7ef;
  border: 2px solid #fcfdff;
  transition: 0.3s;
  top: 28px;
  z-index: 1;

  &.is-connected,
  :hover {
    background-color: color('icon', 'accent');
    outline: 1.5px solid #9296fa;
  }

  &.is-connected > :nth-child(1) > :nth-child(1) {
    fill: color('icon', 'primary-inverted');
  }

  &.is-connected.vue-flow__handle-connecting {
    background-color: color('icon', 'success') !important;
  }

  &.is-connected.vue-flow__handle-connecting.is-invalid {
    background-color: color('icon', 'error') !important;
  }

  &.is-valid {
    background-color: color('icon', 'small-success');
  }

  &.is-disabled {
    pointer-events: none;
    background-color: color('bg', 'box');
  }

  &.is-valid > :nth-child(1) > :nth-child(1) {
    fill: color('icon', 'primary-inverted');
  }
}

.vue-flow__handle-valid {
  background: color('icon', 'success') !important;
}

.vue-flow__handle:hover {
  background-color: color('icon', 'accent');
}

.vue-flow__handle-valid > :nth-child(1) > :nth-child(1),
.vue-flow__handle:hover > :nth-child(1) > :nth-child(1) {
  fill: color('icon', 'primary-inverted');
}
</style>
