<script setup lang="ts">
import type { EdgeProps } from '@vue-flow/core';
import { BezierEdge } from '@vue-flow/core';

const props = defineProps<EdgeProps>();
</script>

<template>
  <BezierEdge v-bind="props" />
</template>
