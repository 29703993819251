<script setup lang="ts">
import type { EdgeProps } from '@vue-flow/core';
import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
  useVueFlow,
} from '@vue-flow/core';
import AddStepButton from './AddStepButton.vue';

const props = defineProps<EdgeProps>();

const { removeEdges } = useVueFlow({
  id: 'editor',
});

const path = computed(() =>
  getBezierPath({
    ...props,
    curvature: 0.5,
    targetX: props.targetX - 5,
    sourceX: props.sourceX + 5,
  }),
);

const isSelected = computed(() => props.selected);

defineShortcuts({
  backspace: {
    whenever: [isSelected],
    handler: () => {
      removeEdges([props.id]);
    },
  },
});
</script>

<template>
  <BaseEdge :id="id" :path="path[0]" :marker-end="markerEnd" />
  <EdgeLabelRenderer>
    <div
      :style="{
        pointerEvents: 'all',
        position: 'absolute',
        transform: `translate(-50%, -50%) translate(${path[1]}px,${path[2]}px)`,
      }"
      class="nodrag nopan"
    >
      <AddStepButton
        :source="props.source"
        :target="props.target"
        type="edge"
      />
    </div>
  </EdgeLabelRenderer>
</template>
<style lang="scss">
.vue-flow__edge:hover .vue-flow__edge-path {
  stroke: #9296fa;
}

.vue-flow__edge.selected .vue-flow__edge-path,
.vue-flow__edge:focus .vue-flow__edge-path,
.vue-flow__edge:focus-visible .vue-flow__edge-path {
  stroke: #e63e34;
  stroke-linecap: square;
  stroke-dasharray: 10, 8;
  stroke-dashoffset: 18;
  animation: lineMove 0.5s infinite linear;
}

.vue-flow__connection-path,
.vue-flow__edge-path {
  stroke: color('icon', 'accent');
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition:
    filter easing(s),
    stroke easing(s);
}
@keyframes lineMove {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
